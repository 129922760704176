import * as Ajax from './ajax'
import * as Common from './common'

//profile-section-block-status-table-wrap


$(document).ready(function () {

  // フッタ初期非表示フラグ
  var hideFooterOnLoad = false;


  /**
   * stylists_controller
   */
  //testcode
  if (document.body.dataset.controller == 'stylists') {

    /* プロフィールカバーとかの調整のJS */
    var default_profile_top = 352 - 136
    var cover_height = 262
    var header_height = $("header").height();
    var profiledetail_height = $(".profile-detail").height();
    var selectedProductHashid = null;

    /* 本ちゃん */
    //$(".profile-top").css('height', default_profile_top + profiledetail_height + header_height);
    //$(".profile-cover").css('padding-top', header_height);
    //$(".wrapper-profile-wrap").css('top', cover_height + header_height);
    //$(".profile-cover-bg-photo-img").css('height', cover_height + header_height);
    //$(".wrapper-profile").css('padding-top', profiledetail_height - 36);

    /* トリートメント対応 */
    var statuslist = 0
    var statusul = 0
    var statuspercent = 0
    var defaulttablefont = 9
    var adjustwidth = 16
    statuslist = ($(".statuslist").width() * 100)
    statusul = $(".profile-section-block-status-table").width();
    statuspercent = statuslist / statusul
    var optimizefont = defaulttablefont - ((adjustwidth - statuspercent) * 0.35)
    //alert("テーブル全体は"+statusul　+",リスト個体は"+statuslist+",%は"+statuspercent+",最適化されたフォントは"+optimizefont);
    
    if (adjustwidth > statuspercent) {
      $(".statuslist").css('font-size', optimizefont + 'px');
    }
    var header_stylistname_text = $(".profile-header-content-center-stylist-name").text();
    var header_stylistname_count = charcount(header_stylistname_text);
    var header_salon_text = $(".profile-header-content-center-salon").text();
    var header_salon_count = charcount(header_stylistname_text);
    //alert(header_stylistname_count);
    if (header_salon_count > 24) {
      $(".profile-header-content-center-salon").css('font-size', (11.5 - ((header_stylistname_count - 24) * 0.15)) + 'px');
      $(".profile-header-content-center-salon").css('letter-spacing', '-0.2px');
    }
    if (header_stylistname_count > 24) {
      $(".profile-header-content-center-stylist-name").css('font-size', (11.5 - ((header_stylistname_count - 24) * 0.2)) + 'px');
      $(".profile-header-content-center-stylist-name").css('letter-spacing', '-0.3px');
      $(".profile-cover-name").css('font-size', (21 - ((header_stylistname_count - 24) * 0.25)) + 'px');
      $(".profile-cover-name").css('letter-spacing', '-0.3px');
      $(".profile-follow-area-name").css('font-size', (20 - ((header_stylistname_count - 24) * 0.25)) + 'px');
      $(".profile-follow-area-name").css('letter-spacing', '0.3px');
    }
    //マッチング名前長い一文字対策
    jQuery(".common-macching-card").each(function () {
      var matching_stylistname_text = $(".common-macching-card-cover-date-left-name", this).text();
      var matching_stylistname_count = charcount(matching_stylistname_text);
      //alert('こんにちわ');
      if (matching_stylistname_count > 20) {
        $(".common-macching-card-cover-date-left-name", this).css('font-size', '15px');
        $(".common-macching-card-cover-date-left-name", this).css('margin', '0 0 8px');
      }
    });
    //カードの高さを返金保証に適応
    var matching_card_height = $(".common-macching-card").height();
    $(".common-matching-henkin-wrapper-foot").height(matching_card_height);


    //limeidあればアプリ内からの画面と認識し、footerを削除
    if (has_limeid()) {
      $("footer").remove();
    }

    const stylist_suidbase = $("header").data("stylist-suidbase")

    //広告用のLINE認証ダイアログのOKボタンクリック
    $('.line_liff_button').click(function () {
      if ($.inArray(stylist_suidbase, AD_SUIDBASES) != -1) {
      // 本番
      trackEvent('LineAuthOK', '508157376039293', '2d414d60-0fba-4d01-ad68-917484a43abf', '2613006979777', 'AW-995271502', 'euw7CJKrwcoZEM7GytoD', 'CQULMG3C77UBOMHHMD6G', 'o740d');    
      }
    })
    
    //インスタログインのローディング画面
    $('#line_rizm_auth_dialog_ec .open-instagram-loading').click(function() {
      $('.profile-loading-instagram').fadeIn();

      const STEP_INFOS_PATTERNS = [

        // A
        [
          { count_rate: 0.3, time_rate: 0.2},
          { count_rate: 0.35,  time_rate: 0.2},
          { count_rate: 0.6,  time_rate: 0.2 },
          { count_rate: 0.65,  time_rate: 0.2 },
          { count_rate: 0.7,  time_rate: 0.1 },
          { count_rate: 1,  time_rate: 0.1 }
        ],
        // B
        [
          { count_rate: 0.03, time_rate: 0.125 },
          { count_rate: 0.23,  time_rate: 0.125 },
          { count_rate: 0.27,  time_rate: 0.125 },
          { count_rate: 0.48,  time_rate: 0.125 },
          { count_rate: 0.55,  time_rate: 0.125 },
          { count_rate: 0.69,  time_rate: 0.125 },
          { count_rate: 0.75,  time_rate: 0.125 },
          { count_rate: 1,  time_rate: 0.125 }
        ],
        // C
        [
          { count_rate: 0.03, time_rate: 0.125 },
          { count_rate: 0.18,  time_rate: 0.125 },
          { count_rate: 0.21,  time_rate: 0.125 },
          { count_rate: 0.39,  time_rate: 0.125 },
          { count_rate: 0.85,  time_rate: 0.2 },
          { count_rate: 0.95,  time_rate: 0.125 },
          { count_rate: 0.99,  time_rate: 0.5 },
          { count_rate: 1,  time_rate: 0.125 }
        ],
        // D
        [
          { count_rate: 0.6, time_rate: 0.16 },
          { count_rate: 0.65,  time_rate: 0.16 },
          { count_rate: 0.68,  time_rate: 0.16 },
          { count_rate: 0.77,  time_rate: 0.16 },
          { count_rate: 0.82,  time_rate: 0.16 },
          { count_rate: 1,  time_rate: 0.17 }
        ]
     ]
  
     // 「インスタログインの準備中」のバーのアニメーションの時間
     var animationDuration = 500;
      
      const randomStepIndex = Math.floor(Math.random() * STEP_INFOS_PATTERNS.length);
      const randomStep = STEP_INFOS_PATTERNS[randomStepIndex];
      const progressBar = $('.instagram-dialog-text-progress-gauge-bar');
      const isProduction = $("header").data("is-production")
      
      let startTime = null;
      let progress = 0;

      function animate(timestamp) {
        if (!startTime) startTime = timestamp;
        const elapsed = timestamp - startTime;
        const timeRatio = Math.min(elapsed / animationDuration, 1);

        const stepIndex = Math.min(Math.floor(timeRatio * randomStep.length), randomStep.length - 1);
        const stepInfo = randomStep[stepIndex];

        progress = stepInfo.count_rate * timeRatio;
        progressBar.css('width', Math.min(progress * 100, 100) + '%');

        if (progress < 1) {
          requestAnimationFrame(animate);
        } else {
          progressBar.css('width', '100%');
          
            

            var stekina_liff_fullview_link = stekina_liff_fullview_url + register_from_and_stype_query
            

            let url = stekina_liff_fullview_link + '&move_to=ec_counseling&ec_product_variants_id=' + selectedProductHashid + "&start_counseling=true"

            //現在のクエリパラメーターからinsta_ec_url_copy_log_id取得
            var insta_ec_url_click_log_id = Common.getParam("insta_ec_url_click_log_id")
            if (insta_ec_url_click_log_id) { //insta_ec_url_copy_log_idがあればurlに追加
                url += "&insta_ec_url_click_log_id=" + insta_ec_url_click_log_id
            }

           // STEKiNAへ遷移
           location.href = url
          $('.profile-loading-instagram').hide();
        }
      }
      requestAnimationFrame(animate);
    })

 

    
    //広告用アカウント
    if ($.inArray(stylist_suidbase, AD_SUIDBASES) != -1) {
    
      //profile-section-block-status-table-wrap
      if(document.URL.match(/utm_medium=instagram/)) {
        $('.title-47773-dialog').children('img').attr('src', 'https://limehair.jp/php74-lime/wp-content/uploads/2022/05/fukidashi13.png');
      }
      
      // ざわゆき以外の広告用アカウントだけ、最初のダイアログ表示する。utm_medium=instagramが含まれていたらざわゆきでも表示する
      if (($.inArray(stylist_suidbase, ZAWAYUKI_SUIDBASES) == -1 || document.URL.match(/utm_medium=instagram/)) && !document.URL.match(/utm_medium=twitter/)) {
        $("#dialog-47773-start").show()
        $(".profile-dialog-fixed-bg").show()
      }

      $(".button-47773-dialog").css({
        opacity: '0'
      });
      $(".text-47773-dialog").css({
        opacity: '0'
      });

      $(".text-47773-dialog").css({
        opacity: '0'
      });
      setTimeout(function () {
        $(".text-47773-dialog").stop().animate({
          opacity: '1'
        }, 500);
      }, 500);
      $(".image-47773-dialog").css({
        opacity: '0'
      });
      setTimeout(function () {
        $(".image-47773-dialog").stop().animate({
          opacity: '1'
        }, 500);
      }, 2500);
      $(".button-47773-dialog").css({
        opacity: '0'
      });
      setTimeout(function () {
        $(".button-47773-dialog").stop().animate({
          opacity: '1'
        }, 500);
      }, 3000);

      $(".button-47773-dialog").click(function () {
        $('.fixed-bottom').hide()
        $("#dialog-47773-start").fadeOut()
        $(".profile-dialog-fixed-bg").fadeOut()
      })
    }

    //プロフィールのヘッダーの名前（サロン名→スタイリスト名に）
    var topBtn = $('.scroll-head-stekina-2019');
    
    // しょうひん詳細ページであればフッタ非表示フラグオン
    if (window.location.hash == '#product-detail-section') {
      var hideFooterOnLoad = true;
    }
    console.log("hideFooterOnLoad",hideFooterOnLoad);

    $(window).scroll(function () {
      // スクロールでフッター表示
      var scroll = $(window).scrollTop();
      if ($(this).scrollTop() < 1) {
        $('.cover-img').velocity({
          height: (cover_height + header_height - scroll),

        }, {
          duration: 0,
          easing: 'easeInOutQuad'
        });
        $('.profile-cover-bg-photo-img').velocity({
          height: (cover_height + header_height + 10 - scroll),

        }, {
          duration: 0,
          easing: 'easeInOutQuad'
        });
        $('.fixed-bottom').hide();
        $('.profile-header-content-center-stylist').hide();
        $('.profile-header-content-center-salon').fadeIn();
        $('.profile-cover-bg-photo-relative').addClass('profile-cover-bg-photo-relative-shadow');

      } else if ($(this).scrollTop() > 1 && $(this).scrollTop() < 249) {
        $('.profile-header-content-center-stylist').hide();
        $('.profile-header-content-center-salon').fadeIn();
        $('.profile-cover-bg-photo-relative').addClass('profile-cover-bg-photo-relative-shadow');
      } else if ($(this).scrollTop() > 250 && $(this).scrollTop() < 500) {
        $('.profile-header-content-center-stylist').fadeIn();
        // フッタ初期非表示でなければ表示
        if (hideFooterOnLoad) {
          hideFooterOnLoad = false;
        } else {
          $('.fixed-bottom').fadeIn();
        }
        $('.profile-header-content-center-salon').hide();
        $('.profile-cover-bg-photo-relative').addClass('profile-cover-bg-photo-relative-shadow');
      } else if ($(this).scrollTop() > 501) {
        $('.profile-header-content-center-stylist').fadeIn();
        $('.fixed-bottom').fadeIn();
        $('.profile-header-content-center-salon').hide();
        $('.profile-cover-bg-photo-relative').removeClass('profile-cover-bg-photo-relative-shadow');
      } else {
        $('.profile-header-content-center-stylist').fadeIn();
        $('.fixed-bottom').fadeIn();
        $('.profile-header-content-center-salon').hide();
        $('.profile-cover-bg-photo-relative').addClass('profile-cover-bg-photo-relative-shadow');
      }
   });

    // スクロール禁止
    function no_scroll() {
      document.addEventListener("mousewheel", scroll_control, {
        passive: false
      });
      document.addEventListener("touchmove", scroll_control, {
        passive: false
      });
    }
    // スクロール禁止解除
    function return_scroll() {
      document.removeEventListener("mousewheel", scroll_control, {
        passive: false
      });
      document.removeEventListener('touchmove', scroll_control, {
        passive: false
      });
    }

    // ----------------------------------------
    // ステキボタン
    // pages.jsのものとは別枠
    // ----------------------------------------

    //文字のバイト数を取得する関数
    function charcount(str) {
      var len = 0;
      var str = escape(str);
      for (var i = 0; i < str.length; i++, len++) {
        if (str.charAt(i) == "%") {
          if (str.charAt(++i) == "u") {
            i += 3;
            len++;
          }
          i++;
        }
      }
      return len;
    }

    // ステキボタン押下
    function click_love_button(el) {
      let numSteki = $(el).data('num-suteki');
      let numTotalSteki = $(el).data('num-total-suteki');
      if (numSteki == 10) {
        return
      }

      const rRecommendId = $(el).data('r-recommend-id');
      const rReviewId = $(el).data('r-review-id');
      const loveButton = $(el)

      setStekiWithAnimate(loveButton, ++numSteki, ++numTotalSteki)

      if (rRecommendId) {
        Ajax.setRecommendLike(rRecommendId, numSteki, (result) => { })
      } else {
        Ajax.setRReviewLike(rReviewId, numSteki, (result) => { })
      }
    }

    $(".love-button-click").click(function () {
      click_love_button($(this))
    })

    // スクロール関連メソッド
    function scroll_control(event) {
      event.preventDefault();
    }

    //
    //シェアタイプがついているいるか？
    //
    function has_stype() {
      if (location.search.indexOf('stype') !== -1) {
        return true
      } else {
        return false
      }
    }

    //
    // register_from=3がついているいるか？
    //
    function has_register_from_stekina() {
      if (location.search.indexOf('register_from=3') !== -1) {
        return true
      } else {
        return false
      }
    }

    //Liff内のアクセスかどうか
    function is_liff_view() {
      return getUrlParameter('channel_id') !== undefined
    }

    //Salon Liff内のアクセスかどうか
    function is_salon_rich_menu() {
      return getUrlParameter('salon_rich_menu') !== undefined
    }

    function is_rizm_app() {
      return getUrlParameter('share-review-button') !== undefined ||
        getUrlParameter('share-recommend-button') !== undefined ||
        getUrlParameter('share-profile-button') !== undefined
    }

    //limeidあるかどうか
    function has_limeid() {
      return $.cookie('limeid') !== undefined
    }

    //レビューシェア同意ダイアログ出すかどうか
    function show_shared_dialog() {
      var show_shared_approval = $("header").data("show-shared-dialog")
      if (show_shared_approval) {
        return true
      }
      return false
    }

    function show_dialog_with_scroll(el) {
      $(el).fadeIn()
      $(".profile-dialog-bg").fadeIn()
      // no_scroll()
    }

    function show_dialog(el) {
      $(el).fadeIn()
      $(".profile-dialog-bg").fadeIn()
      no_scroll()
    }

    function close_dialog() {
      $(".profile-dialog-bg").fadeOut()
      $(".profile-dialog").fadeOut()
      return_scroll()
    }

    function getParam(name) {
      var url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    const ruid = $('.ruid-info').data('ruid')
    const self_login = $("header").data("self-login")
    const fletcher_code = $("header").data("stekina-id")
    const line_channel = $("header").data("line-channel")
    const line_channel_id = $("header").data("line-channel-id")
    const line_app_id_fullview = $("header").data("line-app-id-fullview")
    const liff_url = $("header").data("liff-url")
    const liff_fullview_url = $("header").data("liff-fullview-url")
    const stekina_liff_fullview_url = $("header").data("stekina-liff-fullview-url")
    const register_from_and_stype_query = $("header").data("register-from-and-stype-query")
    const hasReservationPassword = $('header').data('has-reservation-password')
    const is_only_counseling = $("header").data("is-only-counseling")
    const is_only_reservation = $("header").data("is-only-reservation")
    const has_instead_profile = $("header").data("has-instead-profile")
    const is_mobile = $("header").data("is-mobile-accsess")
    const is_pending_followed = $("header").data("is-pending-followed")
    const sa_id = $("header").data("sa-id")
    const reservation_route = getParam("route");
    // 遷移先ページ指定があるかフラグ（next_action（ページ遷移先指定パラメータ）有無）
    const hasNextAction = location.search.indexOf('next_action') !== -1;

    /** --------------------------------------------------------------------
     * next_actionパラメータがsalon_agreementまたはcounselingの場合は（サロンでカウンセリングシート記入導線）
     * お客様がサロン内でフォロー手続きをしているものとし、
     * 担当してもらったことある、ない、選択ダイアログを閉じれないようにする
     */

    if (hasNextAction) {
      // next_actionを取得
      const nextAction = Common.getParam('next_action');
      if (nextAction == "salon_agreement" || nextAction == "counseling_new_answers" || nextAction == "counseling_edit_answer" || nextAction == "counseling_skip_answer") {
        $(".close-profile-dialog").css("display", "none");
        $(".profile-dialog-bg").off();
      } else {
        $(".close-profile-dialog").click(function () {
          close_dialog()
        })
        //　ダイアログ背景タップ
        $(".profile-dialog-bg").click(function () {
          close_dialog()
        })
      }
    } else {
      $(".close-profile-dialog").click(function () {
        close_dialog()
      })
      //　ダイアログ背景タップ
      $(".profile-dialog-bg").click(function () {
        close_dialog()
      })
    }
    /*----------------------------------------------------------------*/

    var authorized_password = false

    $('.follow-dialog-button').click(function () {
      tapped_follow_button()
    })
    $('.disfollow-dialog-button').click(function () {
      tapped_disfollow_button()
    })
    $('.move_to_reservation_date').click(function () {
      tapped_reservation_button()
    })
    $('.move_to_free_reservation_date').click(function () {
      tapped_free_reservation_button()
    })
    $('.move_to_counseling_date').click(function () {
      tapped_counseling_button()
    })
    $(".profile-copon-link").click(function () {
      tapped_coupon_button($(this))
    })
     
    $(document).on('click', '.profile-product-link', function () {
      tapped_product_button($(this))
    })
    $(document).on('click', '.profile-section-block-stekina-product-readmore-text a', function () {
      $('.profile-section-block-shokai-content').removeClass('profile-section-block-shokai-content-product-readmore');
      $(this).hide();
    })

    var line_liff_link = liff_url + register_from_and_stype_query
    var line_liff_fullview_link = liff_fullview_url + register_from_and_stype_query

    if (reservation_route) {
      line_liff_link = liff_url + register_from_and_stype_query + "&route=" + reservation_route
      line_liff_fullview_link = liff_fullview_url + register_from_and_stype_query + "&route=" + reservation_route
    }

    $("a.profile_line_liff_button").attr('href', line_liff_link + '&move_to=profile')
      $("a.menu_line_liff_button").attr('href', line_liff_link + '&move_to=menu')
      $("a.counseling_line_liff_button").attr('href', line_liff_fullview_link + '&move_to=counseling')
    

    //query
    function take_query_params() {
      if (location.search.length == 0) {
        if (has_limeid()) {
          return "&register_from=4"
        }
        return ''
      } else {
        var query_params = location.search.replace("?", "&");
        var stype = getUrlParameter("stype")
        if (stype != undefined || has_limeid()) {
          query_params = query_params + "&register_from=4"
        }
        query_params = query_params.replace("?", "&")
        return query_params
      }
    }


    // 仮フォローだったらフォローダイアログを表示させる
    if (stylist_suidbase && ruid) {
      Ajax.followState(stylist_suidbase, ruid, (follow) => {
        if (follow.resp_pending) {
          show_dialog($('#dialog-follow'))
        } 
      },
        () => {
        //通信環境悪い場合のダイアログ
        show_dialog($('#need-reload'))
        $(".need-reload-button").click(function () {
          location.reload()
        })
      })
    }

    /** --------------------------------------------------------------------
     * next_actionパラメータがsalon_agreementまたはcounselingの場合は（サロンでカウンセリングシート記入導線）
     * お客様がサロン内でフォロー手続きをしているものとし、ダイアログを強制表示する
     */
    if (hasNextAction) {
      // next_actionを取得
      const nextAction = Common.getParam('next_action');
      if (nextAction == "salon_agreement" || nextAction == "counseling_new_answers" || nextAction == "counseling_edit_answer" || nextAction == "counseling_skip_answer") {
        show_dialog($('#dialog-follow'))
        $('body').prepend($('<div>').css({'position': 'fixed', 'width': '100%', 'height': '100%', 'background-color': '#ffffff', 'top': 0, 'z-index': 7}))
      }
    }


    // ラインログインダイアログ
    $(".line-login-dialog-button").click(function () {
      $('.line_liff_button').hide()
      var move_to = $('#line_login_dialog').attr('data-from-move-to');
      if (move_to == 'profile') {
        $(".profile_line_liff_button").show();
      } else if (move_to == 'menu') {
        $(".menu_line_liff_button").show();
      } else if (move_to == 'counseling') {
        $(".counseling_line_liff_button").show();
      } else if (move_to == 'coupon') {
        $(".coupon_line_liff_button").show();
      }
      show_dialog($("#line_rizm_auth_dialog"))
    })

    // 【EC用】ラインログインダイアログ
    $(".line-login-dialog-button-ec").click(function () {
      $('.line_liff_button').hide()
      var move_to = $('#line_login_dialog').attr('data-from-move-to');
      if (move_to == 'profile') {
        $(".profile_line_liff_button").show();
      } else if (move_to == 'menu') {
        $(".menu_line_liff_button").show();
      } else if (move_to == 'counseling') {
        $(".counseling_line_liff_button_ec").show();
      } else if (move_to == 'coupon') {
        $(".coupon_line_liff_button").show();
      }
      show_dialog($("#line_rizm_auth_dialog_ec"))
    })

    // 予約パスワードのチェック
    $('.check-reservation-password-button').click(function () {
      const stekinaId = $('header').data('stekina-id')
      const password = $('.reservation-password').val()
      const $alertInvalid = $('.alert-invalid-reservation-password')

      Ajax.checkReservationPassword(stekinaId, password, (result) => {
          if (result) {
            close_dialog()
            authorized_password = true
            var from = $('#dialog-reservation-password').data('from-password')
            switch (from) {
              case "follow":
                tapped_follow_button()
                break
              case "disfollow":
                tapped_disfollow_button()
                break
              case "reserve":
                tapped_reservation_button()
                break
              case "message":
                tapped_counseling_button()
                break
              case "coupon":
                tapped_coupon_button()
                break
              case "product":
                tapped_product_button()
                break
            }
            authorized_password = false
          } else {
            // エラー表示
            $alertInvalid.show()
          }
        },
        () => {
          $alertInvalid.show()
        })
    })


    //直行URL
    function direct_link(move_to) {
      var link = '/reserve/' + fletcher_code + '/' + move_to + '?fletcher_code=' + fletcher_code + register_from_and_stype_query
      if (move_to == 'counseling') {
        link += ('&line_app_id=' + line_app_id_fullview)
      }
      location.href = link
    }

    //
    // フォローダイアログボタンのクリック =============================
    //
    function tapped_follow_button() {

      var is_salon_account = $("header").data("is-salon-account")
      //RiZMアプリ内、代理登録のプロフィールの場合ボタンinactive
      if (is_rizm_app() || has_instead_profile == true) {
        return
      }

      //
      //PCブラウザだった場合
      if (is_mobile == false) {
        show_dialog($('#qr-code-dialog'))
      }
      //LiMEアプリ内
      else if ((has_limeid() && !self_login) || is_salon_rich_menu()) {
        if (is_salon_account) {
          //店舗アカウントだったら全フォロー
          followAllStylists();
        } else {
          //フォローダイアログ表示
          show_dialog($('#dialog-follow'))
        }
      }
      //liff内
      else if (is_liff_view()) {
        if (is_salon_account) {
          //店舗アカウントだったら全フォロー
          followAllStylists();
        } else {
          //フォローダイアログ表示
          show_dialog($('#dialog-follow'))
        }
      }
      //LINEへ
      else {
        $('#line_login_dialog').attr('data-from-move-to', 'profile')
        show_dialog($('#line_login_dialog'))
      }
    }


    //
    // フォロー解除ダイアログボタンのクリック =================================
    //
    function tapped_disfollow_button() {
      if (is_rizm_app() || has_instead_profile == true) {
        return
      }

      // 予約パスワードが設定されている場合、パスワード入力ダイアログ
      //if(hasReservationPassword && !authorized_password) {
      //  $('#dialog-reservation-password').attr('data-from-password', 'disfollow')
      //  show_dialog($('#dialog-reservation-password'))
      //}
      if (is_mobile == false) {
        show_dialog($('#qr-code-dialog'))
      }
      //アンフォローダイアログを表示する
      else if ((has_limeid() && !self_login) || is_salon_rich_menu()) {
        show_dialog($('#dialog-disfollow'))
      }
      //liff内の制御
      else if (is_liff_view()) {
        show_dialog($('#dialog-disfollow'))
      }
      //LINEへ
      else {
        $('#line_login_dialog').attr('data-from-move-to', 'profile')
        show_dialog($('#line_login_dialog'))
      }
    }

    //
    // 「空席を確認してみる」押下
    //
    function tapped_reservation_button() {
      close_dialog()
      //PCブラウザだった場合
      if (is_mobile == false) {
        show_dialog($('#qr-code-dialog'))
      }
      // 予約パスワードが設定されている場合、パスワード入力ダイアログ
      else if (hasReservationPassword && !authorized_password) {
        $('#dialog-reservation-password').attr('data-from-password', 'reserve')
        show_dialog($('#dialog-reservation-password'))
      }
      //LiMEアプリ内
      else if ((has_limeid() && !self_login) || is_salon_rich_menu()) {
        // 直リンク
        direct_link('menu')
      }
      //liff内
      else if (is_liff_view()) {
        //liffリンク
        location.href = line_liff_link + '&move_to=menu'
      } else {
        $('#line_login_dialog').attr('data-from-move-to', 'menu')
        show_dialog($('#line_login_dialog'))
      }
    }

    //
    // 店舗アカウント用
    // プロフィール画面で予約ボタンを押下した際に先に指名無し予約でよいか確認ダイアログを表示させる
    //
    function tapped_free_reservation_button() {
      close_dialog()
      show_dialog($('#dialog-free-reservation'))

      //PCブラウザだった場合
      if (is_mobile == false) {
        show_dialog($('#qr-code-dialog'))
      }
      // 予約パスワードが設定されている場合、パスワード入力ダイアログ
      else if (hasReservationPassword && !authorized_password) {
        $('#dialog-reservation-password').attr('data-from-password', 'reserve')
        show_dialog($('#dialog-reservation-password'))
      }
      //LiMEアプリ内
      else if ((has_limeid() && !self_login) || is_salon_rich_menu()) {
        // 直リンク
        direct_link('menu')
      }
      //liff内
      else if (is_liff_view()) {
        //liffリンク
        location.href = line_liff_link + '&move_to=menu'
      } else {
        $('#dialog-free-reservation').attr('data-from-move-to', 'menu')
        show_dialog($('#dialog-free-reservation'))
      }
    }

    //
    // 指名無しで予約しますか？
    //
    $('.free-reserve-button').click(function () {
      const stekinaId = $(this).data('stekina-id')
      const free_button = $(this).data('button')
      //はい
      if (free_button) {
        //ラインダイアログへ
        //PCブラウザだった場合
        if (is_mobile == false) {
          show_dialog($('#qr-code-dialog'))
        }
        // 予約パスワードが設定されている場合、パスワード入力ダイアログ
        else if (hasReservationPassword && !authorized_password) {
          $('#dialog-reservation-password').attr('data-from-password', 'reserve')
          show_dialog($('#dialog-reservation-password'))
        }
        //LiMEアプリ内
        else if ((has_limeid() && !self_login) || is_salon_rich_menu()) {
          // 直リンク
          direct_link('menu')
        }
        //liff内
        else if (is_liff_view()) {
          //liffリンク
          location.href = line_liff_link + '&move_to=menu'
        } else {
          $('#line_login_dialog').attr('data-from-move-to', 'menu')
          show_dialog($('#line_login_dialog'))
        }
      } 
      //いいえ
      else {
        close_dialog()
      }
    })


    //
    // 「メッセージする」カウンセリングに遷移
    //
    function tapped_counseling_button() {
      //PCブラウザだった場合
      if (is_mobile == false) {
        show_dialog($('#qr-code-dialog'))
      }
      //そのまま予約画面に遷移
      // 予約パスワードが設定されている場合、パスワード入力ダイアログ
      else if (hasReservationPassword && !authorized_password) {
        $('#dialog-reservation-password').attr('data-from-password', 'message')
        show_dialog($('#dialog-reservation-password'))
      }
      //LiMEアプリ内
      else if ((has_limeid() && !self_login) || is_salon_rich_menu()) {
        direct_link('counseling')
      }
      //liff内
      else if (is_liff_view()) {
        //liffの直リング
        location.href = line_liff_fullview_link + '&move_to=counseling'
      }
      //LINEへ
      else {
        $('#line_login_dialog').attr('data-from-move-to', 'counseling')
        show_dialog($('#line_login_dialog'))
      }
    }


    //
    // クーポンタップ
    //
    var coupon_links = ''

    function tapped_coupon_button(el) {
      if (is_rizm_app() || has_instead_profile == true) {
        return
      }
      if (el != undefined) {
        coupon_links = get_coupon_link(el)
      }
      //PCブラウザだった場合
      if (is_mobile == false) {
        show_dialog($('#qr-code-dialog'))
      }
      // 予約パスワードが設定されている場合、パスワード入力ダイアログ
      else if (hasReservationPassword && !authorized_password) {
        $('#dialog-reservation-password').attr('data-from-password', 'coupon')
        show_dialog($('#dialog-reservation-password'))
      }
      //LiMEアプリ内
      else if (has_limeid() && !self_login || is_salon_rich_menu()) {
        //クーポン直リンク
        if (is_only_counseling == true) {
          direct_link('counseling')
        } else {
          location.href = coupon_links[0]
        }
      }
      //liff内の直リンク
      else if (is_liff_view()) {
        if (is_only_counseling == true) {
          location.href = line_liff_fullview_link + '&move_to=counseling'
        } else {
          location.href = coupon_links[1]
        }
      } else {
        if (is_only_counseling == true) {
          $('#line_login_dialog').attr('data-from-move-to', 'counseling')
        } else {
          $("a.coupon_line_liff_button").attr('href', coupon_links[1] + '&move_to=date');
          $('#line_login_dialog').attr('data-from-move-to', 'coupon')
        }
        show_dialog($('#line_login_dialog'))
      }
    }


    //
    // オススメの美容商品/化粧品タップ
    //
    
    // var product_links = ''

    function tapped_product_button(el) {
      selectedProductHashid = $("#purchase-consult-button").attr('data-hashid');
      if (is_rizm_app() || has_instead_profile == true) {
        return
      }
      // if (el != undefined) {
      //   product_links = get_coupon_link(el)
      // }
      //PCブラウザだった場合
      if (is_mobile == false) {
        show_dialog($('#qr-code-dialog'))
      }
      // 予約パスワードが設定されている場合、パスワード入力ダイアログ
      else if (hasReservationPassword && !authorized_password) {
        $('#dialog-reservation-password').attr('data-from-password', 'product')
        show_dialog($('#dialog-reservation-password'))
      }
      //LiMEアプリ内
      else if (has_limeid() && !self_login || is_salon_rich_menu()) {
        //クーポン直リンク
        if (is_only_reservation == true) {
          // FIX ME!! 相談メッセージ飛んじゃダメ？
          direct_link('counseling')
        } else {
          direct_link('counseling')
          // location.href = product_links[0]
        }
      }
      //liff内の直リンク
      else if (is_liff_view()) {
        if (is_only_reservation == true) {
          // FIX ME!! 相談メッセージ飛んじゃダメ？
          location.href = line_liff_fullview_link + '&move_to=counseling'
        } else {
          location.href = line_liff_fullview_link + '&move_to=counseling'
          // location.href = product_links[1]
        }
      } else {
        if (is_only_reservation == true) {
          // FIX ME!! 相談メッセージ飛んじゃダメ？
          $('#line_login_dialog').attr('data-from-move-to', 'counseling')
        } else {
          $('#line_login_dialog').attr('data-from-move-to', 'counseling')
        }
        show_dialog($('#line_login_dialog_ec'))
      }
    }

    $('.profile-coupon-availabel-balloon').one('inview', function (event, isInView) {
      //いったんテスト環境だけ動くように
      if (STEKINA_PATH.indexOf('test') != -1) {
        check_occuied($(this))
      }
    });


    //
    // メニューの空席予約状態取得する
    //
    function check_occuied(el) {
      var current_date = new Date()
      var start_date = Math.floor(current_date.getTime() / 1000)

      var today_last_date = new Date()
      today_last_date.setHours(23)
      today_last_date.setMinutes(59)
      today_last_date.setSeconds(59)
      var end_date = Math.floor(today_last_date.getTime() / 1000)
      var m_suid = $(el).data('m-suid')
      var m_suidbase = $(el).data('suidbase')
      var $balloon_section = $(el)

      Ajax.check_occupied_reservation(fletcher_code, start_date, end_date, m_suidbase, m_suid, (occupied) => {
        console.log(occupied)
        if (occupied == 'false') {
          $balloon_section.html('<div class="profile-section-block-coupon-menu-available-today"> <img src="https://limehair.jp/wp-https-lime/wp-content/uploads/2020/05/speech-today-available.png"></div>')
        }
      }, (data) => {
        console.log("failed")
        $balloon_section.html('')
      })
    }

    function get_coupon_link(el) {
      var date = new Date()
      var time = new Date(date.setMinutes(date.getMinutes() + 30)).getTime()
      var reserve_time = Math.floor(time / 1000)
      var suidbase = $(el).data("suidbase")
      var m_suid = $(el).data("m-suid")
      var m_name = $(el).data("m-name")
      var cost = $(el).data("cost")
      var how_long = $(el).data("how-long")
      var selected_menu = "[{:suidbase " + suidbase + " :m_suid " + m_suid + " :m_name \"" + m_name + "\" :cost " + cost + " :how_long " + how_long + "}]"
      var encode_selected_menus = encodeURIComponent(selected_menu)
      var selected_menus_query = '&selected_menus=' + encode_selected_menus
      var reserve_start_time = '&reserve_start_time=' + reserve_time
      var reservation_route = getParam("route");

      $.removeCookie('reserve_start_time', {
        path: '/reserve/' + fletcher_code
      });
      $.removeCookie('selected_menus', {
        path: '/reserve/' + fletcher_code
      });

      var link = '/reserve/' + fletcher_code + '/date?reserve_from_sv=stekinasv' + reserve_start_time + register_from_and_stype_query + selected_menus_query
      var liff_link = liff_url + "&move_to=date" + reserve_start_time + selected_menus_query + register_from_and_stype_query

      if (reservation_route) {
        link = link + '&route=' + reservation_route;
        liff_link = liff_link + '&route=' + reservation_route;
      }

      return [link, liff_link]
    }

    /**
     * 指定ページに遷移
     */
    function toNextPage(){
      // next_actionを取得
      const nextAction = Common.getParam('next_action');
      const counseling_type = Common.getParam('counseling_type');
      // 指定ページに遷移
      switch (nextAction) {
        // 同意書画面
        case 'salon_agreement':
          location.href = `/user/counseling/salon_agreement?suidbase=${stylist_suidbase}&sa_id=${sa_id}&ruid=${ruid}&counseling_type=${counseling_type}`;
          break;
        // カウンセリングシート
        case 'counseling_new_answers':
          location.href = `/user/counseling/new_answers?suidbase=${stylist_suidbase}&sa_id=${sa_id}&line_app_id=${line_app_id_fullview}`;
          break;
        case 'counseling_edit_answer':
          location.href = `/user/counseling/edit_answer?suidbase=${stylist_suidbase}&sa_id=${sa_id}&line_app_id=${line_app_id_fullview}`;
          break;
        // マイカルテ
        case 'counseling_skip_answer':
          location.href = `/user/kartes?suidbase=${stylist_suidbase}&sa_id=${sa_id}&line_app_id=${line_app_id_fullview}`;
          break;
      
        default:
          break;
      }
    }

    //
    // フォローボタンのクリック
    //
    $('.follow-button').click(function () {
      const stekinaId = $(this).data('stekina-id')
      const resp = $(this).data('resp')
      close_dialog()
      if (resp == 0) {
        // 担当してもらったことあります
        show_dialog($('#dialog-follow-profile'))
      } else {
        // ないです初めてです
        
        /** --------------------------------------------------------------------
         * next_actionパラメータがsalon_agreementまたはcounselingの場合は（サロンでカウンセリングシート記入導線）
         * お客様がサロン内でフォロー手続きをしているものとし、
         * 名前入力欄を出して終わる
         */
        if (hasNextAction) {
          const nextAction = Common.getParam('next_action');
          if (nextAction == "salon_agreement" || nextAction == "counseling_new_answers" || nextAction == "counseling_edit_answer" || nextAction == "counseling_skip_answer") {
            show_dialog($('#dialog-follow-profile'))
            return false;
          }
        }

        Ajax.followStylist(stekinaId, ruid, resp, (_followCount) => {

          $('button.follow-dialog-button').removeClass('follow-dialog-button')
            .addClass('disfollow-dialog-button')
            .html('<span>フォロー中</span>')
            .off('click')
            .click(function () {
              tapped_disfollow_button()
            })
  
          $('.header-profile-button').find('div.follow-dialog-button').removeClass('follow-dialog-button')
            .addClass('disfollow-dialog-button')
            .html('<a class="profile-detail-button-sub-follow-active">フォロー中</a>')
            .off('click')
            .click(function () {
              tapped_disfollow_button()
            })
  
          $('.fixed-bottom').find('div.follow-dialog-button').removeClass('follow-dialog-button')
            .addClass('disfollow-dialog-button')
            .html('<a class="fixed-bottom-button-sub-follow-active">フォロー中</a>')
            .off('click')
            .click(function () {
              tapped_disfollow_button()
            })
        }, () => {})
      }
    })

    //
    // 「担当してもらったことあります」選択時のプロフィール入力完了ボタン
    //
    $('.follow-profile-confirm-button').click(function () {
      const stekinaId = $(this).data('stekina-id')
      const resp = $(this).data('resp')
      const name = $('input[name="follow_profile_name"]').val()
      const pronounce = $('input[name="follow_profile_pronounce"]').val()
      const sex = $('input[name="follow_profile_sex"]:checked').val()
      var error = false
      //性別
      if (sex == undefined) {
        $(".profile-validation").show().text("性別を選択してください")
        error = true
      }

      //よみがな
      if (pronounce.length == 0) {
        $(".profile-validation").show().text("よみがなを記入してください")
        error = true
      } else if (pronounce.length > 0 && !Common.isHiragana(pronounce)) {
        $(".profile-validation").show().text("よみがなはひらがなで記入してください")
        error = true
      }

      //名前
      if (name.length == 0) {
        $(".profile-validation").show().text("名前を入力してください")
        error = true
      }

      if (error) {
        return false
      }

      $(".profile-validation").hide()

      close_dialog()

      /** --------------------------------------------------------------------
       * respの書き換え
       * next_actionパラメータがsalon_agreementまたはcounselingの場合は（サロンでカウンセリングシート記入導線）
       * お客様がサロン内でフォロー手続きをしているものとし、
       * 「無いです初めてです！」の場合も「今日初めて担当してもらいます！」と同じ扱いとする
       *  （フォロー時の申請内容(Responsibility) 0:担当してもらったことがある 1:担当してもらったことはない 2:今日初めて担当）
       */
      console.log('next_action（ページ遷移先指定パラメータ）有無フラグ', hasNextAction);
      if (hasNextAction) {
        // next_actionを取得
        const nextAction = Common.getParam('next_action');
        if (nextAction == "salon_agreement" || nextAction == "counseling_new_answers" || nextAction == "counseling_edit_answer" || nextAction == "counseling_skip_answer") {
          if (resp == 1) {
            resp = 2;
          }
        }
      }

      console.log('is_counselingありますか？')
      // 初期値として isCounseling を false に設定
      var isCounseling = false; 
      try{
        var isCounselingParam = getParam('is_counseling');
        // 文字列の'is_counseling'をbooleanに変換する
        var isCounseling = isCounselingParam == 'true';
        console.log(`is_counseling パラメータの値は '${isCounselingParam}' です。変換後の isCounseling は ${isCounseling} です。`);
      }catch (error) {
        // エラー処理: 例外が投げられた場合の処理をここに記述
        console.error('is_counseling の取得中にエラーが発生しました:', error);
      }

      /*----------------------------------------------------------------*/

      // プロフィール情報入力付きのフォローAPIを叩く
      Ajax.followStylistWithProfile(stekinaId, ruid, resp, name, pronounce, sex, isCounseling, (_followCount) => {

        $('button.follow-dialog-button').removeClass('follow-dialog-button')
          .addClass('disfollow-dialog-button')
          .html('<span>フォロー中</span>')
          .off('click')
          .click(function () {
            tapped_disfollow_button()
          })

        $('.header-profile-button').find('div.follow-dialog-button').removeClass('follow-dialog-button')
          .addClass('disfollow-dialog-button')
          .html('<a class="profile-detail-button-sub-follow-active">フォロー中</a>')
          .off('click')
          .click(function () {
            tapped_disfollow_button()
          })

        $('.fixed-bottom').find('div.follow-dialog-button').removeClass('follow-dialog-button')
          .addClass('disfollow-dialog-button')
          .html('<a class="fixed-bottom-button-sub-follow-active">フォロー中</a>')
          .off('click')
          .click(function () {
            tapped_disfollow_button()
          })


        // next_actionパラメータがある場合は指定された飛び先に遷移
        if (hasNextAction) {
          toNextPage();
        }
      }, () => {})
    })

    //
    // サロン内全員フォロー
    //
    function followAllStylists() {
      var sa_id = $("header").data("sa-id")
      Ajax.followSalonAllStylists(sa_id, (result) => {
        if(result) {
          // 全フォローボタンにフォロー済みclassを適用
          $('button.follow-dialog-button').removeClass('follow-dialog-button')
            .addClass('disfollow-dialog-button')
            .html('<span>フォロー中</span>')
            .off('click')
            .click(function () {
              tapped_disfollow_button()
            })
  
          $('.header-profile-button').find('div.follow-dialog-button').removeClass('follow-dialog-button')
            .addClass('disfollow-dialog-button')
            .html('<a class="profile-detail-button-sub-follow-active">フォロー中</a>')
            .off('click')
            .click(function () {
              tapped_disfollow_button()
            })
  
          $('.fixed-bottom').find('div.follow-dialog-button').removeClass('follow-dialog-button')
            .addClass('disfollow-dialog-button')
            .html('<a class="fixed-bottom-button-sub-follow-active">フォロー中</a>')
            .off('click')
            .click(function () {
              tapped_disfollow_button()
            })
        }
      }, () => {}) 
    }

    //
    // フォロー解除ボタンのクリック
    //
    $('.disfollow-button').click(function () {
      close_dialog()
      const stekinaId = $(this).data('stekina-id')
      Ajax.disfollowStylist(stekinaId, ruid, () => {
        $('button.disfollow-dialog-button').removeClass('disfollow-dialog-button')
          .addClass('follow-dialog-button')
          .html('<span>フォロー</span>')
          .off('click')
          .click(function () {
            tapped_follow_button()
          })

        $('.header-profile-button').find('div.disfollow-dialog-button').removeClass('disfollow-dialog-button')
          .addClass('follow-dialog-button')
          .html('<a class="profile-detail-button-sub-follow">フォロー</a>')
          .off('click')
          .click(function () {
            tapped_follow_button()
          })

        $('.fixed-bottom').find('div.disfollow-dialog-button').removeClass('disfollow-dialog-button')
          .addClass('follow-dialog-button')
          .html('<a class="fixed-bottom-button-sub-follow">フォロー</a>')
          .off('click')
          .click(function () {
            tapped_follow_button()
          })
      }, () => {})
    })

    //
    // フォロー解除キャンセルボタンのクリック
    //
    $('.disfollow-cancel-button').click(function () {
      close_dialog()
    })

    //
    // [マッチング用] フッターの保存するボタンのクリック
    //
    $('#profile-footer-save-button').on('click', function () {
      if ($(this).attr("class").indexOf("save-active") == -1) {
        animateSavedStylist($(".save-speech-followarea"))
        const rUid = $(this).data('r-uid')
        const stekinaId = $(this).data('stekina-id')
        savedStylist(rUid, stekinaId)
      } else {
        const rUid = $(this).data('r-uid')
        const stekinaId = $(this).data('stekina-id')
        unsaveStylist(rUid, stekinaId)
      }
    })

    //
    // [マッチング用] プロフィールトップの下部にfloatしている「保存する」ボタンのクリック
    // （salon_info.counseling != 0のとき表示される）
    //
    $('#profile-float-save-button').on('click', function () {
      if ($(this).attr("class").indexOf("save-active") == -1) {
        animateSavedStylist($(".fixed-bottom-matching-speech"))
        const rUid = $(this).data('r-uid')
        const stekinaId = $(this).data('stekina-id')
        savedStylist(rUid, stekinaId)
      } else {
        const rUid = $(this).data('r-uid')
        const stekinaId = $(this).data('stekina-id')
        unsavedStylist(rUid, stekinaId)
      }

    })

    //
    // [マッチング用] プロフィールトップの美容師データのところにある「保存する」ボタンのクリック
    //（salon_info.counseling != 0のとき表示される）
    //
    $('#profile-detail-save-button').on('click', function () {
      if ($(this).attr("class").indexOf("save-active") == -1) {
        animateSavedStylist($(".save-speech-top"))
        const rUid = $(this).data('r-uid')
        const stekinaId = $(this).data('stekina-id')
        savedStylist(rUid, stekinaId)
      } else {
        const rUid = $(this).data('r-uid')
        const stekinaId = $(this).data('stekina-id')
        savedStylist(rUid, stekinaId)
      }
    })

    //
    // [マッチング用] プロフィールヘッダの「保存する」ボタンのクリック
    //
    $('#profile-header-save-button').on('click', function () {
      if ($(this).attr("class").indexOf("save-active") == -1) {
        // 吹き出し
        animateSavedStylist($(".common-macching-card-detail-aboslute-speech-save"))
        // フォロー処理
        const rUid = $(this).data('r-uid')
        const stekinaId = $(this).data('stekina-id')
        savedStylist(rUid, stekinaId)
      } else {
        // アンフォロー処理
        const rUid = $(this).data('r-uid')
        const stekinaId = $(this).data('stekina-id')
        unsaveStylist(rUid, stekinaId)
      }      
    })

    //
    // [マッチング用] 各「保存する」ボタンクリック時に実行するフォロー処理
    //
    function savedStylist(rUid, stekinaId) {
      // フォロー実行
      Ajax.followStylist(stekinaId, rUid, 1, (followCount) => {
        var $top_button = $('#profile-header-save-button')
        var $middle_button = $('#profile-detail-save-button')
        var $fix_button = $('#profile-float-save-button')
        var $footer_button = $('#profile-footer-save-button')

        // クラス付け替え・ボタン文言変更
        $top_button.toggleClass('save-active').text("保存済み")
        $middle_button.toggleClass('save-active').text("保存済み")
        $fix_button.toggleClass('save-active').text("保存済み")
        $footer_button.toggleClass('save-active').text("保存済み")
      }, () => {})
    }

    //
    // [マッチング用] 各「保存する」ボタン押下時の吹き出しアニメーション
    //
    function animateSavedStylist(el) {
      $(el).show()
        setTimeout(function () {
          $(el).animate({
            opacity: 0
          }, {
            duration: 500,
            easing: 'linear'
          })
        }, 1000);
        setTimeout(function () {
          $(el).css('z-index', '-99999');
        }, 2000);
    }

    //
    // [マッチング用] 各「保存済み」ボタンクリック時に実行するフォロー解除処理
    //
    function unsaveStylist(rUid, stekinaId) {
      // フォロー解除実行
      Ajax.disfollowStylist(stekinaId, rUid, () => {
        var $top_button = $('#profile-header-save-button')
        var $middle_button = $('#profile-detail-save-button')
        var $fix_button = $('#profile-float-save-button')
        var $footer_button = $('#profile-footer-save-button')

        // クラス付け替え・ボタン文言変更
        $top_button.toggleClass('save-active').text("保存する")
        $middle_button.toggleClass('save-active').text("保存する")
        $fix_button.toggleClass('save-active').text("保存する")
        $footer_button.toggleClass('save-active').text("保存する")
      }, () => {})
    }

    //
    // シェアして応援する
    //
    $('.jquery-open-dialog-share-sns').click(function () {
      var current_url = location.href.split("?")[0] + "?stype=rp"
      var text = "#STEKiNA #ステキナ"
      var $share_dialog = $("#dialog-share-sns")

      $share_dialog.find(".facebook-share-link").attr("href", "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(current_url) + "&t=" + text)
      $share_dialog.find(".twitter-share-link").attr("href", "https://twitter.com/share?url=" + encodeURIComponent(current_url) + "&text=" + encodeURIComponent(text))
      $share_dialog.find(".line-share-link").attr("href", "http://line.me/R/msg/text/?" + encodeURIComponent(text + " " + current_url))
      $share_dialog.find(".url-copy-share-link").click(function () {
        //コピー
        let $textarea = $('<textarea></textarea>');
        $textarea.text(current_url);
        $(this).append($textarea);
        $textarea.select();
        document.execCommand('copy');
        $textarea.remove();

        close_dialog()
        show_dialog($('#dialog-share-sns-url-copied'))
      })
      show_dialog($('#dialog-share-sns'))
    })

    $(".close-dialog").on("click", function () {
      close_dialog()
    });


    //
    // レビューの返信を更新
    //
    $(".form-review-reply").submit(function (el) {
      var review_id = $('.button-reply-review').data('review-id')
      var comment = $('#review_reply_textarea').val()
      //レビュー返信更新
      Ajax.sendReviewReplies(review_id, comment, (result) => {
        if (result.success) {
          var updated_comment = result.updated_comment.replace(/\n/g, '<br>')//改行処理
          $('.profile-section-block-review-comment-byclient').fadeIn()
          $('.profile-section-block-review-comment-byclient-content').html(updated_comment)
        } else {
          // failed
        }
        //アプリに「アプリレビュー書きませんか？」通知を渡す。
        if (is_mobile) {
          location.href = "lime-community://?action=show_app_review"
        }
      })
      //元はformでレビュー返信を更新、追加していたのでformからsubmitしないように
      return false
    })


    const stekina_profile_path = STEKINA_PATH + '/profile/'
    var review_load_count = 0
      var recommend_load_count = 0
    //   var product_load_count = 0
    var instagram_load_count = 0
    // レビュー・ステキナ紹介文、画像一覧表示エリアを非同期表示
    load_reviews()
    load_recommends()

    // オススメ美容商品表示エリアを非同期表示
    load_products()   

    //instagramロード
    $('.instagram-panel-area').one('inview', function (event, isInView) {
      loadInstagram()
    });

    //プロフィールトップのレビューを取得
    function load_reviews() {
      $.ajax({
        url: stekina_profile_path + fletcher_code + '/review_overviews' + location.search,
        type: 'get',
      }).done(function (data) {
        $('.review-overviews-area').html(data)
        $('.jquery-open-dialog-review-count-0').click(function () {
          show_nothing_review_dialog()
        })
        $(".love-button-click").unbind().click(function () {
          click_love_button($(this))
        })
      }).fail((jqXHR, textStatus, errorThrown) => {
        console.log('fail', jqXHR.status);
        if (review_load_count == 0) {
          load_reviews()
          review_load_count += 1
        }
      })
    }



    //プロフィールトップのステキナ紹介文を取得
    function load_recommends() {
      $.ajax({
        url: stekina_profile_path + fletcher_code + '/recommend_overviews' + location.search,
        type: 'get',
      }).done(function (data) {
        //
        $('.recommend-overviews-area').html(data)
        //ここにかく！
        //スタイリスト名を取得
        var recommend_stylistname = $(".stylistnamelength").text();
        //取得したスタイリスト名をバイト数に変換
        var recommend_stylistname_count = charcount(recommend_stylistname);
        if (recommend_stylistname_count > 12) {
          $(".stylistnamelength").css('font-size', (12.5 - ((recommend_stylistname_count - 12) * 0.27)) + 'px');
          $(".stylistnamelength").css('letter-spacing', '-0.5px');
        }


        //clickをバインドする
        $('.jquery-open-dialog-shokai-not-install').click(function () {
          show_howto_post_recommend_dialog()
        })
        $('.new_recommend').click(function () {
          new_post_recommend($(this))
        })
        $('.register_stekina_dialog').click(function () {
          register_stekina_dialog()
        })
        $(".love-button-click").unbind().click(function () {
          click_love_button($(this))
        })
      }).fail((jqXHR, textStatus, errorThrown) => {
        console.log('fail', jqXHR.status);
        if (recommend_load_count == 0) {
          load_reviews()
          recommend_load_count += 1
        }
      })
    }

    //プロフィールトップのオススメ美容商品を取得
    function load_products() {
        $.ajax({
          url: stekina_profile_path + fletcher_code + '/product_overviews' + location.search,
          type: 'get',
        }).done(function (data) {
            $('.product-overviews-area').html(data)
            
        }).fail((jqXHR, textStatus, errorThrown) => {
          console.log('fail', jqXHR.status);
        //   if (product_load_count == 0) {
        //     load_products()
        //     product_load_count += 1
        //   }
        })
      }

    // Instagram画像一覧表示エリアがある場合、非同期で取得して表示する
    function loadInstagram() {
      const afterKey = $('.instagram-paging-info').data('after')
      const url = stekina_profile_path + fletcher_code + '/instagram_panel'
      $.ajax({
        url: url + (afterKey != undefined ? '?after=' + afterKey : ''),
        type: 'get',
      }).done(function (data) {
        $('.wrapper-loader-instagram').remove()
        $('.instagram-paging-info').remove()
        $('.instagram-panel-area .medias').append($(data))
      }).fail((jqXHR, textStatus, errorThrown) => {
        console.log('fail', jqXHR.status);
        if (instagram_load_count == 0) {
          loadInstagram()
          instagram_load_count += 1
        }
      })
    }

    //youtube遅延ロード
    var youtube_url = $('.profile-section-block-youtube').data('youtube-url')
    var youtube_iframe = '<iframe width="100%" height="315" src="' + youtube_url + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>'
    $('.profile-section-block-youtube').html(youtube_iframe)


    var address = $('.profile-section-block-googlemap').data('address')
    var google_iframe = '<iframe src="' + address + '" width="100%" height="245" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>'
    $('.profile-section-block-googlemap').html(google_iframe)
    
    //googleマップ遅延ロード
    $('.profile-section-block-googlemap').one('inview', function (event, isInView) {
      
    })


    //
    // ステキナ紹介文の編集 =========================
    //
    $('.button-edit-recommend').click(function () {
      const recommendId = $(this).data('recommend-id')
      const $dialog = $(this).parent().find('#dialog-edit-stekina-shokai')
      show_dialog($dialog)
      $dialog.find('form').append('<input type="hidden" name="id" value="' + recommendId + '" />')
    })

    //
    // レビューへの返信 ============================
    //
    $('.button-reply-review').click(function () {
      const reviewId = $(this).data('review-id')
      const $dialog = $('#dialog-reply-review')
      show_dialog($dialog)
      $dialog.find('form').append('<input type="hidden" name="id" value="' + reviewId + '" />')
    })

    //レビューがないとき
    function show_nothing_review_dialog() {
      show_dialog("#dialog-review-count-zero")
    }

    //ステキナ紹介文がないとき
    function show_howto_post_recommend_dialog() {
      show_dialog("#dialog-shokai-not-install")
    }

    //ステキナ紹介文投稿画面へ
    function new_post_recommend(el) {
      $('.dialog-community').parent().hide();
      var suidbase = $(el).data("stylist-suidbase")
      var clickUrl = '/community/recommends/new_with_to_stylist/' + suidbase
      location.href = clickUrl
    }

    //ステキナ紹介文投稿しようとしてステキナ未登録だった場合のダイアログ
    function register_stekina_dialog() {
      $('.dialog-community').parent().show();
    }

    // ダイアログ背景で閉じる
    $('body').on('click', '.dialog-community', function (e) {
      $('.dialog-community').parent().hide();
    })

    // メニュー詳細の展開
    $('.toggle_menu').click(function () {
      const opened = $('.menus').data('opened') == true
      if (opened) {
        $('.short-menus').hide()
        $('.long-menus').show()
      } else {
        $('.short-menus').show()
        $('.long-menus').hide()
      }
      $('.menus').data('opened', !opened)
    })


    // Youtube動画URL承認申請ダイアログ
    $('.youtube-movie-registration-button').click(function () {
      show_dialog($('.youtube-movie-registration-dialog'))
    })

    // Youtube動画URL承認申請処理
    $('.youtube-movie-registration-dialog form').submit(function (e) {
      $('.alert-invalid-url').hide()
      // バリデーション
      const url = $('.youtube-movie-url').val()
      if (url.length == 0 ||
        (!url.startsWith("https://youtube.com") &&
          !url.startsWith("https://www.youtube.com") &&
          !url.startsWith("https://youtu.be") &&
          !url.startsWith("https://m.youtube.com"))) {
        $('.alert-invalid-url').show()
        e.preventDefault()
      } else {
        // $('.youtube-movie-registration-complete-dialog').show()
      }
    })
    // ダイアログクローズ
    $('.youtube-movie-registration-complete-dialog > .complete-dialog-ok-button').click(function () {
      location.reload()
    })


    $("button.register_stekina_dialog").on("click", function () {
      $('.dialog-community').parent().show();
    });

    // ダイアログ背景で閉じる
    $('body').on('click', '.dialog-community', function (e) {
      $('.dialog-community').parent().hide();
    })

    /**
     * show action プロフィール画面
     * [GET] profile/:stekina_id
     */
    if (document.body.dataset.action == 'show') {
      $('.button-edit-hygienes').click(function () {
        show_dialog_with_scroll($('#hygienes-edit-dialog'))
      })
    }

    /**
     * レビュー詳細画面のとき
     */
    var reviewDialogTimer;
    if (document.body.dataset.action == 'review') {
      //LiMEアプリ内かつセルフログインのとき
      if (has_limeid() && self_login && show_shared_dialog()) {
        reviewDialogTimer = setInterval(function(){
          clearInterval(reviewDialogTimer)
          show_dialog($("#dialog-please-share"))
          setTimeout(function(){
            $('.button-share-ok').animate({opacity: 1}, 400);
          },2000);
        },3000);
      }

       //レビュー投稿者の名前が長い時の文字数対策
      var custmername_text = $(".name-client-charcount").text();
      var custmername_count = charcount(custmername_text);
      if (custmername_count> 18) {
        $(".name-client-charcount").css('font-size', (18 - ((custmername_count - 18) * 0.37)) + 'px');
        $(".name-client-charcount").css('letter-spacing', '-0.5px');  
      }
    } else {
      if (reviewDialogTimer) {
        clearInterval(reviewDialogTimer)
      }
    }


    /**
     * reviews action レビュー一覧画面
     * [GET] profile/:stekina_id/review
     */
    if (document.body.dataset.action == 'reviews') {
      /**
       * レビュー一覧を読み込む
       * @param {boolean} retry エラー時にリトライを行う
       */
      function loadReviews(page = 1, scrollToStart = false, retry = false) {
        // FIXME: ローディングアニメーション有効化・表示中ページを消すなどはここで
        $.ajax({
          url: stekina_profile_path + fletcher_code + '/reviews_part?page=' + page,
          type: 'get',
          data: {
            "query": location.search
          }
        }).done(function (data) {
          $('.reviews-part').html(data)
          // 指定ある場合開始位置へスクロール
          // FIXME: 任意のアニメージョンを設定する場合はここのwindow.scrollを変更
          scrollToStart && window.scroll(0, $('.reviews-part').offset().top)
          // ステキボタンの動作を定義
          $(".love-button-click").unbind().click(function () {
            click_love_button($(this))
          })
          // ページャーの動作を定義
          $('.reviews-pager-link').click(function (e) {
            e.preventDefault()
            loadReviews(e.target.dataset.page, true, false)
          })
        }).fail((jqXHR, textStatus, errorThrown) => {
          console.log('fail', jqXHR.status);
          // retry && loadReviews(page, scrollOnLoad, false)
        })
      }
      loadReviews(1, false, true)
    }
      
    /**
     * products action オススメ美容商品一覧画面
     * [GET] profile/:stekina_id/product
     */
    if (document.body.dataset.action == 'products') {
        /**
         * オススメ美容商品一覧を読み込む
         * @param {boolean} retry エラー時にリトライを行う
         */
        function loadProducts(page = 1, scrollToStart = false, retry = false) {
          // FIXME: ローディングアニメーション有効化・表示中ページを消すなどはここで
          $.ajax({
            url: stekina_profile_path + fletcher_code + '/products_part?page=' + page,
            type: 'get',
            data: {
              "query": location.search
            }
          }).done(function (data) {
            $('.products-part').html(data)
            // 指定ある場合開始位置へスクロール
            // FIXME: 任意のアニメージョンを設定する場合はここのwindow.scrollを変更
            scrollToStart && window.scroll(0, $('.products-part').offset().top)

            // ページャーの動作を定義
            $('.products-pager-link').click(function (e) {
              e.preventDefault()
              loadProducts(e.target.dataset.page, true, false)
            })
          }).fail((jqXHR, textStatus, errorThrown) => {
            console.log('fail', jqXHR.status);
            // retry && loadReviews(page, scrollOnLoad, false)
          })
        }
        loadProducts(1, false, true)
    }
  }
    
  /**
   * matching action マッチングしたスタイリストの一覧画面
   * [GET]stylists/matching
   */
  if (document.body.dataset.action == 'matching') {

    document.cookie = 'baloon_photo_shown=1'

    //
    // ライン認証/連携用URL
    //
    const lineConnectUrl = $("#matching_data").data("line-connect-url")
    //
    // ライン認証/連携用URL
    //
    const myLiffId = $("#matching_data").data("line-app-id-fullview")
    var accessToken = ''
    //
    // ライン認証/連携用URL
    //
    function initializeLiff(myLiffId) {
      liff
        .init({
          liffId: myLiffId
        })
        .then(() => {
          // アクセストークン取得
          accessToken = liff.getAccessToken()
        })
        .catch((err) => {
          alert(err)
        });
    }
    //
    // LIFF初期化
    //
    $(function () {
      initializeLiff(myLiffId)
    })

    // 全額100%返金保証の期限カウントダウンタイマー
    const endDateTime = new Date($('#TimeLeft2').data('expire-date'))

    var m // 分
    var s // 秒

    function countDown() {
      const leftDateTime = endDateTime - new Date()
      if (leftDateTime > 0) {
        // 残時間
        const leftMinutes =  parseInt(leftDateTime / 1000 / 60)
        const leftSeconds = parseInt(leftDateTime / 1000 % 60)
        // DOM表記書き換え
        $("#TimeLeft").html('<span class="countdown-number">' + leftMinutes + '</span>分<span class="countdown-number">' + leftSeconds + '</span>秒')
        $("#TimeLeft2").html('<span class="countdown-number">' + leftMinutes + '</span>分<span class="countdown-number">' + leftSeconds + '</span>秒')
        // 自身を1秒ごとに呼び出し
        setTimeout(countDown, 1000)
      }
    }

    countDown()

    //
    // ライン連携ダイアログの初回表示
    //
    setTimeout(function () {
      $("#line-connect-dialog").removeClass("add_class").addClass("add_class").fadeIn()
    }, 1000)
    //
    // ページ訪問時の返金キャンペーンダイアログの初回表示（ライン連携ボタンが押されていた場合に出す）
    //
    setTimeout(function () {
      $("#henkin-dialog").removeClass("add_class").addClass("add_class").fadeIn()
    }, 1000)
    //
    // プロフィール画像のクリック
    //
    $('.common-macching-card').click((e) => {
      document.cookie = 'baloon_tap_photo_tapped=0'
      $('.common-macching-card-speech-tap-photo').hide()
    })
    //
    // フォローボタンのクリック
    //
    $('.follow-button').click(function () {
      Ajax.followStylist($(this).data('stekinaId'), (followCount) => {
        $(this).parent().addClass('saved')
      })
    })
    //
    // ライン連携ダイアログのボタンのクリック
    //
    $('#line-connect-dialog-close').on('click', function () {
      gtag('event', 'matching-click', {
        'event_category': 'click',
        'event_label': '06_ラインログイン数'
      });
      document.cookie = 'line_connect_dialog_tapped=0'
      location.href = lineConnectUrl
      $('#line-connect-dialog').hide()
    })
    //
    // ページ訪問時の返金キャンペーンダイアログのボタンのクリック
    //
    $('#henkin-dialog-close').on('click', function () {
      document.cookie = 'henkin_dialog_tapped=0'
      $('#henkin-dialog').hide()
    })
    //
    // 「返金保証の詳細はコチラ」リンクのクリック
    //
    var scrollPosition;
    $('#henkin-detail-dialog-open').on('click', function () {

      $('#henkin-detail-dialog').fadeIn()
      $('#henkin-detail-dialog-bg').fadeIn()
      scrollPosition = $(window).scrollTop();
      $('body').addClass('fixed').css({
        'top': -scrollPosition
      });
    })
    //
    // 「返金保証の詳細はコチラ」ダイアログ内の閉じるボタンのクリック
    //
    $('.henkin-detail-dialog-close').on('click', function () {
      $('#henkin-detail-dialog').hide()
      $('#henkin-detail-dialog-bg').hide()
      $('body').removeClass('fixed').css({
        'top': 0
      });
      window.scrollTo(0, scrollPosition);
    })
    //
    // 「もう一度質問に答えて美容師さんを探してみませんか？」ダイアログ内の閉じるボタンのクリック
    //
    $('#retry-dialog-close').on('click', function () {
      $('#retry-dialog').hide()
    })
    //
    // 保存ボタンのクリック
    //
    $('.common-macching-card-detail-button-save').on('click', function () {
      $(this).next().addClass('common-macching-card-detail-aboslute-speech-save-animation')
      $(this).addClass('saved')
      $(this).html('<div class="common-macching-card-detail-button-save-triger"><span>保存済み</span></div>')
    })

    $('.button-follow.not-saved').click(function () {
      const rUid = $(this).data('r-uid')
      const stekinaId = $(this).data('stekina-id')
      Ajax.followStylist(stekinaId, rUid, 1, (followCount) => {
        $(this).addClass('saved')
      }, () => {})
    })
  }
})


/**
 * アニメーションつきでステキ数のセットを行う
 */
const STEKI_METER_HEIGHTS = ['0%', '25%', '33%', '40%', '48%', '56%', '65%', '74%', '83%', '92%', '100%']

function setStekiWithAnimate(loveButton, numSteki, numTotalSteki) {
  var loveButtonParent = loveButton.parent()
  loveButton.data('num-suteki', numSteki)
  loveButton.data('num-total-suteki', numTotalSteki)

  var animationLoveButton = loveButton.next('.love-button')
  animationLoveButton.addClass('sutekiscalenormal').delay(500).queue(function (next) {
    animationLoveButton.removeClass('sutekiscalenormal')
    next()
  })

  loveButtonParent.find('.suteki-counter').animate({
    'opacity': '1',
  }, 0)

  loveButtonParent.find('.suteki-counter').fadeIn('100')
  loveButtonParent.find('.suteki-counter').fadeOut('5000')
  loveButtonParent.find('.mask-color').css('height', STEKI_METER_HEIGHTS[numSteki])

  if (numSteki >= 10) {
    loveButtonParent.find('.flush-heart').addClass('target')
    loveButtonParent.addClass('liked', 400, 'swing', () => {
      loveButtonParent.find('.flush-heart').removeClass('target')
    })
    loveButtonParent.find('.mask-color').css('background', 'white')
    loveButtonParent.find('.love-button').addClass('suteki-scale')
    loveButtonParent.find('.suteki-counter-number').css('font-size', '30px')
  }

  loveButtonParent.parent().find('.love-button-count-span').html(function (i, val) {
    return numTotalSteki
  })
  loveButtonParent.parent().find('.suteki-counter-number').html(function (i, val) {
    return numSteki
  })
}

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
}
